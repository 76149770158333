import React, { useContext, useEffect } from "react"
import { navigate } from "gatsby"
import { AuthContext } from "../context/AuthContext"

const Logout = () => {
  const { logout } = useContext(AuthContext)

  useEffect(() => {
    logout()

    if (typeof window == "undefined") {
      return
    }

    try {
      window.FB.getLoginStatus((response) => {
        if (response.status === "connected") {
          window.FB.logout()
        }
      })
    } catch (err) {}

    try {
      window.gapi.auth2.getAuthInstance().signOut()
    } catch (err) {}

    navigate("/")
  }, [logout])

  return <></>
}

export default Logout
